import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
let sectionConfig = require('../sectionConfig.json');

const Navigation = () => {
    const [ expanded, setExpanded ] = useState(false);

    let sections: { id: string, title: string, text: string, imgSrc: string, textFirst: boolean}[] = [];
    Object.keys(sectionConfig[0]).forEach(function(key) {
        sections.push(sectionConfig[0][key]);
    });

    const handleClick = (id: string) => {        
        if(id === "about") { id = "header"; }
        const navbar = document.getElementById("navbar");
        const element = document.getElementById(id);
        if(element && navbar) {
            const y = element.getBoundingClientRect().top + window.pageYOffset - (navbar.getBoundingClientRect().height + 35);
            window.scroll({ top: y, behavior: "smooth" });
        }
        setExpanded(false);
    };

    return (
        <Navbar id="navbar" expanded={expanded} bg="primary" expand="md" sticky="top" >
            <Container>
            <Navbar.Toggle onClick={() => { setExpanded(!expanded); }} aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
                <Nav className="justify-content-center">
                {sections.map(s => <Nav.Item><Nav.Link onClick={() => { handleClick(s.id); }}>{s.title}</Nav.Link></Nav.Item>)}
                </Nav>                        
            </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;