import './App.scss';
import './App.css';
import Brand from '../src/Components/Brand';
import Navigation from './Components/Navigation';
import Sections from '../src/Components/Sections';

function App() {
  return (    
  <>
    <Brand />
    <Navigation />
    <div className="App">
      <Sections />
    </div>
  </>
  );
}

export default App;
