import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    picture: {
        flex: 2,
        flexBasis: "320px",
        display: "flex",
        width: "40%",        
        overflow: "hidden",
        justifyContent: "center"
    },
    image: {
        height: "100%",
        width: "auto"
    },
    imageNoCrop: {
        width: "auto",
        maxWidth: "100%"
    }
}));

const PhotoSection = (props: {
    src: string,
    fallbackSrc: string,
    noCrop: boolean
}) => {
    const {src, fallbackSrc, noCrop} = props;

    const classes = useStyles();

    return src ? 
    <picture className={classes.picture}>
        <source srcSet={require(`../Images/${src}`).default} type="image/webp" />
        <img alt="" className={noCrop ? classes.imageNoCrop : classes.image} {...props} src={require(`../Images/${fallbackSrc}`).default} />
    </picture>
     : null;
}

export default PhotoSection;