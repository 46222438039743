import React from 'react';
import { makeStyles } from '@material-ui/core';
import PhotoSection from './PhotoSection';
import TextSection from './TextSection';

const useStyles = makeStyles(() => ({
    section: {
        display: "flex",        
        flexDirection: "row",        
        flexWrap: "wrap",
        alignItems: "start",
        backgroundColor: "rgba(166, 182, 177, 0.3)",
        marginLeft: "40px",
        marginRight: "40px",
        marginBottom: "40px"
    },
    textFirst: {
        ["@media (max-width:856px)"]: {
            flexWrap: "wrap-reverse"
        },
    }
}));

const Section = (props: {
    id: string,
    title: string,
    textSections: { subtitle: string, text: string, html: string }[],
    imgSrc: string,
    imgFallbackSrc: string,
    imgNoCrop: boolean,
    textFirst: boolean
}) => {
    const { id, title, textSections, imgSrc, imgFallbackSrc, imgNoCrop = false, textFirst } = props;

    const classes = useStyles();

    return <div className={classes.section + (textFirst ? " " + classes.textFirst : "") }>
        { textFirst ? <><TextSection id={id} title={title} textSections={textSections} /><PhotoSection src={imgSrc} fallbackSrc={imgFallbackSrc} noCrop={imgNoCrop} /></>
        : <><PhotoSection src={imgSrc} fallbackSrc={imgFallbackSrc} noCrop={imgNoCrop} /><TextSection id={id} title={title} textSections={textSections} /></> }
    </div>
}

export default Section;