import { makeStyles } from '@material-ui/core';
import Section from './Section';
let sectionConfig = require('../sectionConfig.json');

const useStyles = makeStyles(() => ({
    root: {
        ':not(:first-child)': {
            marginTop: "35px"
        }        
    }
}));

const Sections = () => {
    const classes = useStyles();

    let sections: { id: string, title: string, textSections: { subtitle: string, text: string, html: string }[], imgSrc: string, imgFallbackSrc: string, imgNoCrop: boolean, textFirst: boolean}[] = [];
    Object.keys(sectionConfig[0]).forEach(function(key) {
        sections.push(sectionConfig[0][key]);
    });

    return <div className={classes.root}>{sections.map((s) => {
        return <Section {...s} />
    })}
    </div>;
    
}

export default Sections;