import { ReactElement, useMemo } from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    textSection: {
        flex: 3,
        flexBasis: "440px",
        width: "60%",
        padding: "20px"
    },
    title: {
        fontSize: "24px",
        fontWeight: 600,
        marginTop: "10px",
        marginBottom: "15px",
        fontFamily: "Poiret One"
    },
    subHeader: {
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "5px",
        marginBottom: "5px",
        fontFamily: "Poiret One"
    },
    textSubsection: {
        paddingBottom: "12px"
    }
}));

const TextSection = (props: {
    title: string,
    id: string,
    textSections: { subtitle: string, text: string, html: string}[]
}) => {
    const { title, id, textSections } = props;
    const classes = useStyles();

    const textBlocks = useMemo(() => {
        let blocks: ReactElement[] = [];
        textSections.forEach(t => {
            if(t.subtitle?.length > 0){
                blocks.push(<div className={classes.subHeader}>{t.subtitle}</div>);
            }
            if(t.text?.length > 0) {                
                blocks.push(<div className={classes.textSubsection}>{t.text}</div>);
            }
            if(t.html?.length > 0) {
                blocks.push(<div className={classes.textSubsection} dangerouslySetInnerHTML={{__html: t.html}}></div>);
            }
            
        });
        return blocks;
    }, [textSections, classes]);

    return <div id={id} className={classes.textSection}><div className={classes.title}>{title}</div>{textBlocks}</div>
}

export default TextSection;