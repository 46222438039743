import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        textAlign: "center",
        marginTop: "10px",
        marginBottom: "10px"
    },
    brandName: {
        fontFamily: "Poiret One",
        fontSize: "28px",
        fontWeight: 900,        
        marginBottom: "10px"
    },
    license: {
        fontSize: "12px"        
    }
}));

const Brand = () => {
    const classes = useStyles();

    return (
    <header id="header" className={classes.root}>
        <div className={classes.brandName}>Sea to Summit Counseling</div>
        <div>Brendon Manno</div>
        <div className={classes.license}>LMFT #122506</div>
        <div className={classes.license}>LPCC #12743</div>
    </header>
    );
}

export default Brand;